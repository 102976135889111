
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/Forum/Widget/index.tsx';

  import {
    withLeanStyles
  } from '@wix/native-components-infra';

  
var initI18n = null;

  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
var I18nextProvider = React.Fragment;


      import { PureExperimentsProvider } from '@wix/yoshi-flow-editor';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

  import { TPAComponentsProvider } from 'wix-ui-tpa';

var BaseUiEnvironmentProviderWrapper = React.Fragment;

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
  TPAComponentsProvider,
  BaseUiEnvironmentProviderWrapper,
}


  import stylesParams from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/Forum/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://baf30a2b91654c5a840931f0137bed30@sentry.wixpress.com/1696',
      id: 'baf30a2b91654c5a840931f0137bed30',
      projectName: 'communities-forum-client',
      teamName: 'forum',
      
    };

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withLeanStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: '1489040e-001f-4631-55a9-2c29b4417126',
    name: 'Forum',
    withErrorBoundary: true,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    
    component: UserComponent,
    loadChunks
  };
